import React, { useEffect, useState } from 'react';
import { Input, Table, Modal, Form, Row, Col, Button, Spin, Select } from "antd";
import { useGetRegisteredUsersMutation, useUpdateUserStatusMutation, useSendUserInviteMutation, useResendInviteUserMutation, useChnageUserRoleMutation } from "../../redux/slices/userSlice";
import Swal from "sweetalert2";
import moment from 'moment';
import { MdRefresh } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import debounce from '../../HelperFunctions';

export default function RegisteredUsers() {
	const { Option } = Select;
	const [form] = Form.useForm();
	const [modalOpen, setModalOpen] = useState(false);
	const [getRegisteredUsers, { isLoading: isUserDataLoading }] = useGetRegisteredUsersMutation();
	const [updateUserStatus, { isLoading: isUpdateUserLoading }] = useUpdateUserStatusMutation();
	const [sendUserInvite, { isLoading: isSendInviteLoading }] = useSendUserInviteMutation();
	const [resendInviteUser] = useResendInviteUserMutation();
	const [chnageUserRole, { isLoading: isChangeUserRoleLoading }] = useChnageUserRoleMutation();
	const [userPerPage, setUserPerPage] = useState(10);
	const [userPage, setUserPage] = useState(1);
	const [userTotal, setUserTotal] = useState(0);
	const [filteredUserData, setFilteredUserData] = useState([]);
	const [userId, setUserId] = useState();
	const [userSearch, setUserSearch] = useState('');
	const [sortField, setSortField] = useState(null);
	const [sortOrder, setSortOrder] = useState(null);

	const getUserData = (query) => {
		getRegisteredUsers(query).then((response) => {
			setFilteredUserData(response?.data?.user?.data);
			setUserTotal(response?.data?.user?.total);
		}).catch((error) => {
			console.error({ error })
		})
	}

	useEffect(() => {
		let query = `?per_page=${userPerPage}&page=${userPage}`;
		if (sortField && sortOrder) {
			query += `&sort_by=${sortField}&sort_direction=${sortOrder}`;
		}
		if (userSearch !== '') {
			query += `&search=${userSearch}`
		 }
		getUserData(query)
	}, [userPerPage, userPage, sortOrder, userSearch, sortField])


	const onFinish = (value) => {
		let formData = {
			"email": value?.email,
			"name": value?.name,
			"role": value?.role
		}

		sendUserInvite({ value: formData }).then((response) => {
			setUserPage(1)
			setModalOpen(false)
			form.resetFields()
			const query = `?per_page=${10}&page=${1}`;
			getUserData(query)
			if (response?.error) {
				if (response?.error?.data?.message?.includes("The user is already registered")) {
					Swal.fire({
						title: "Invite Not Sent.",
						text: `${response?.error?.data?.message}`,
						icon: "info",
						confirmButtonText: "OK",
					})
				} else {


					Swal.fire({
						title: "Resend Invite.",
						text: `${response?.error?.data?.message}`,
						icon: "info",
						showCancelButton: true,
						cancelButtonText: "Cancel",
						confirmButtonText: "Resend",
						reverseButtons: true,
					}).then((result) => {
						if (result.isConfirmed) {
							let ResendForm = {
								"email": value?.email,
								"role": value?.role
							}
							resendInviteUser(ResendForm).then((response) => {
								if (response?.error) {
									Swal.fire({
										title: "Failed",
										text: `${response?.error?.data?.message}`,
										icon: "error",
									})
								} else {
									Swal.fire({
										title: "Resend Invite.",
										text: `${response?.data?.message}`,
										icon: "success",
									})
								}

								const query = `?per_page=${10}&page=${1}`;
								getUserData(query)
							}).catch(() => {
								Swal.fire({
									title: "Failed",
									text: `Unable to send invite`,
									icon: "error",
								})
							})
						} else if (result.isDismissed) {
							Swal.close();
						}
					});
				}
			} else {
				Swal.fire({
					title: "Invite sent.",
					text: `${response?.data?.message}`,
					icon: "success",
					confirmButtonText: "OK",
				});
			}

		}).catch((error) => {
			setUserPage(1)
			form.resetFields()
			setModalOpen(false)
			Swal.fire({
				title: "Invite failed.",
				text: `${error?.data?.message}`,
				icon: "error",
				confirmButtonText: "OK",
			});
		})


	}

	const onFinishFailed = (error) => {
		console.error({ error })
	}

	const generatePageSizeOptions = (total) => {
		const options = [];
		const maxPageSize = Math.min(total, 100);

		for (let i = 5; i <= maxPageSize; i += 5) {
			options.push(i);
		}
		if (total > 5 && !options.includes(10)) {
			options.push(10);
		}

		if (total > 10 && !options.includes(15)) {
			options.push(15);
		}
		return options.sort((a, b) => a - b);
	};

	const pageSizeOptions = generatePageSizeOptions(userTotal);

	const onPageSizeChange = (current, size) => {
		setUserPerPage(size);
		setUserPage(1);
	};

	const handleActivatUser = (value) => {
		let formData = { "status": "active" }
		updateUserStatus({ Id: value?.id, value: formData }).then((response) => {
			const query = `?per_page=${userPerPage}&page=${userPage}`;
			getUserData(query)
			if (response?.error) {
				Swal.fire({
					title: "User Activation failed.",
					text: `${response?.error?.data?.message}`,
					icon: "error",
					confirmButtonText: "OK",
				});
			} else {
				Swal.fire({
					title: "User Activated",
					text: `${response?.data?.message}`,
					icon: "success",
					confirmButtonText: "OK",
				});
			}

		}).catch((error) => {
			Swal.fire({
				title: "User Activation failed.",
				text: `${error?.data?.message}`,
				icon: "error",
				confirmButtonText: "OK",
			});
		})
	}

	const handleDeactivatUser = (value) => {
		setUserId(value?.id)
		let formData = { "status": "deactive" }
		updateUserStatus({ Id: value?.id, value: formData }).then((response) => {
			const query = `?per_page=${userPerPage}&page=${userPage}`;
			getUserData(query);
			if (response?.error) {
				Swal.fire({
					title: "User Activation failed.",
					text: `${response?.error?.data?.message}`,
					icon: "error",
					confirmButtonText: "OK",
				});
			} else {
				Swal.fire({
					title: "User Deactivated",
					text: `${response?.data?.message}`,
					icon: "success",
					confirmButtonText: "OK",
				});
			}
			setUserId()

		}).catch((error) => {
			setUserId()
			Swal.fire({
				title: "User Activation failed.",
				text: `${error?.data?.message}`,
				icon: "error",
				confirmButtonText: "OK",
			});
		})
	}

	const handleRoleChange = (role, record) => {
		setUserId(record?.id)
		Swal.fire({
			title: "Chnage user role.",
			text: `Are you sure you want to change the role of ${record?.name}?`,
			icon: "info",
			showCancelButton: true,
			confirmButtonText: 'Proceed',
			cancelButtonText: 'Cancel',
		}).then((result) => {
			if (result.isConfirmed) {
				let formData = {
					user_id: record?.id,
					role: role
				}
				chnageUserRole(formData).then((response) => {
					if (response?.error) {
						Swal.fire({
							title: "Failed",
							text: `Unable to update user role.`,
							icon: "error",
							confirmButtonText: "OK",
						});
					} else {
						Swal.fire({
							title: "Success",
							text: `${response?.data?.message}`,
							icon: "success",
							confirmButtonText: "OK",
						}).then(() => {
							window?.location?.reload()
						});

					}
					setUserId()
				}).catch((error) => {
					setUserId()
					Swal.fire({
						title: "Failed.",
						text: `Unable to update role of the user.`,
						icon: "error",
						confirmButtonText: "OK",
					});
				})
			}
		});
	}

	const userListColumn = [
		{
			title: 'S.No',
			dataIndex: 'sno',
			key: 'sno',
			render: (_, record, index) => (userPage - 1) * userPerPage + index + 1,
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			width: '20%',
			sorter: true,
		},
		{
			title: 'Email ',
			dataIndex: 'email',
			key: 'email',
			width: '30%',
			sorter: true,
		},
		{
			title: 'Role',
			dataIndex: 'role',
			key: 'role',
			width: "10%",
			render: (text, record) => (
				<>
					{isChangeUserRoleLoading && record?.id === userId ? (<Spin />) : (
						record?.invitation_status === "pending" ? (
							<Select disabled defaultValue={record?.role === 'nurse' ? "nurse" : "doctor"} className="audience-status-dropdown">
								<Option value="nurse">Nurse</Option>
								<Option value="doctor">Admin</Option>
							</Select>
						) : (
							<Select defaultValue={record?.role === 'nurse' ? "nurse" : "doctor"} className="audience-status-dropdown" onChange={(e) => handleRoleChange(e, record)}>
								<Option value="nurse">Nurse</Option>
								<Option value="doctor">Admin</Option>
							</Select>
						)
					)}
				</>
			),
		},
		{
			title: 'Created On',
			dataIndex: 'created_on',
			key: 'created_on',
			width: "15%",
			sorter: true,
			render: (text, record) => (
				<>
					<span>{moment(record.created_at).format('MM/DD/YY hh:mm:ss A')}</span>
				</>
			)
		},
		{
			title: 'Last Login',
			dataIndex: 'last_login',
			key: 'last_login',
			sorter: true,
			width: "15%",
			render: (text, record) => (
				<>
					<span>{record.last_login ? (moment(record.last_login).format('MM/DD/YY hh:mm:ss A')) : "N/A"}</span>
				</>
			)
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (text, record) => (
				<div className='action-button'>
					{record?.invitation_status === "pending" ? (
						<span>Pending</span>
					) : record?.status === "active" ? (
						<span>Active</span>
					) : (
						<span>Deactivated</span>
					)}
				</div>
			)

		},
		{
			title: 'Action',
			dataIndex: 'action',
			key: 'action',
			render: (text, record) => (
				isUpdateUserLoading && (record?.id === userId) ? (<Spin />) : (
					<div className='action-button'>
						{record?.invitation_status === "pending" ? (
							<>N/A</>
						) : record?.status === "active" ? (
							<button
								className='deactivate-button'
								onClick={() => handleDeactivatUser(record)}
							>
								Deactivate
							</button>
						) : (
							<button
								className='activate-button'
								onClick={() => handleActivatUser(record)}
							>
								Activate
							</button>
						)}

					</div>
				)
			)

		},
	]

	const handleUserSearch = async (event) => {
		setUserPage(1)
		setUserSearch(event?.target?.value);
	};

	const handleTableChange = (pagination, filters, sorter) => {
		if (sorter.order) {
			setSortField(sorter.field);
			setSortOrder(sorter.order === "ascend" ? "asc" : "desc");
		} else {
			setSortField(null);
			setSortOrder(null);
		}
	};

	return (
		<>
			<div className="wrapper">
				<div className="container-fluid">
					<div className="row mb-5">
						<div className="col-md-12">
							<div className="titleBar titleRow">
								<h4>Registered Users</h4>
								<div className='search-invite-wrapper rightWrapper d-flex justify-content-end align-items-end'>
									<div className='inputContainer' style={{marginBottom:"0px"}}>
									<label htmlFor="lastVisitSelect" className="dynamicLabel">Search Here</label>
									<Input
									style={{height:"49px"}}
										value={userSearch}
										placeholder="Search here.."
										onChange={handleUserSearch}
										className="homesearchInput titleRowInput customHomeSearchPadding"
										allowClear
									/>
									</div>
									<Button style={{height:"49px"}} icon={<FaPlus />} onClick={() => setModalOpen(true)}>
										Invite User
									</Button>
								</div>

							</div>
						</div>
						<div className="col-md-12">
							<div className="tableWrapper">
								<Table
									className="registered-user-table"
									dataSource={isUserDataLoading ? [] : filteredUserData}
									columns={userListColumn}
									pagination={{
										current: userPage,
										pageSize: userPerPage,
										total: userTotal,
										onChange: (page) => setUserPage(page),
										onShowSizeChange: onPageSizeChange,
										showSizeChanger: true,
										pageSizeOptions: pageSizeOptions,
										showQuickJumper: true,
									}}
									showSorterTooltip={false}
									onChange={handleTableChange}
									bordered
									loading={isUserDataLoading}
									scroll={{ x: 'auto' }}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Modal
				title="Invite User"
				className='invite-user-modal'
				centered
				open={modalOpen}
				onOk={() => { setModalOpen(false); form.resetFields() }}
				onCancel={() => { setModalOpen(false); form.resetFields() }}
				footer={false}
				width={900}
			>
				<Form
					name="shippingForm"
					layout="vertical"
					initialValues={true}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					autoComplete="off"
					className='invite-user-form'
					form={form}
				>
					{/* First and Last Name */}
					<Row gutter={24}>
						<Col span={8}>
							<Form.Item
								name="name"
								label="Name"
								rules={[{ required: true, message: "Please enter name." }]}
							>
								<Input placeholder="Enter name" />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								name="email"
								label="Email"
								rules={[
									{
										required: true,
										message: "Please enter a valid email.",
										type: "email",
									},
									{
										validator(_, value) {
											const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z]*[a-zA-Z0-9]+[a-zA-Z]+\.[a-zA-Z]{2,4}$/;
											if (value && !regex.test(value)) {
												return Promise.reject(
													"Invalid email address. The website name must contain both letters and numbers, and the domain should not exceed 4 characters."
												);
											}
											return Promise.resolve();
										},
									},
								]}
							>
								<Input
									maxLength={30}
									placeholder="Enter email address"
									onInput={(e) => {
										let value = e.target.value;
										value = value.replace(/['"\s]/g, '');

										const atIndex = value.indexOf('@');
										if (atIndex !== -1) {
											const localPart = value.slice(0, atIndex + 1);
											let domainPart = value.slice(atIndex + 1);
											domainPart = domainPart.replace(/[0-9]/g, '');
											const domainRegex = /\.(com|org|net|co|edu|gov|io|info|biz|[a-zA-Z]{2,4})$/;
											const domainMatch = domainPart.match(domainRegex);
											if (domainMatch) {
												domainPart = domainPart.slice(0, domainMatch.index + domainMatch[0].length);
											}
											const domainSplit = domainPart.split('.');
											if (domainSplit.length > 1 && domainSplit[1].length > 4) {
												domainSplit[1] = domainSplit[1].slice(0, 4);
												domainPart = domainSplit.join('.');
											}

											value = localPart + domainPart;
										}

										e.target.value = value;
									}}
								/>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item
								name="role"
								label="Select Role"
								rules={[{ required: true, message: "Please select role." }]}
							>
								<Select options={[
									{
										value: "doctor",
										label: "Admin"
									},
									{
										value: "nurse",
										label: "Nurse"
									}
								]} placeholder="Select Role" />
							</Form.Item>
						</Col>
					</Row>
					<div className="actionWrapper">
						{/* Submit Button */}
						<Form.Item>
							<Button type="primary" disabled={isSendInviteLoading} icon={<MdRefresh style={{ fontSize: "20px", marginRight: "0.5rem" }} />}
								loading={isSendInviteLoading} htmlType="submit" block>
								Send Invite
							</Button>
						</Form.Item>
					</div>
				</Form>
			</Modal>

		</>
	)
}
