import React, { useState, useEffect } from "react";
import { Table, Spin, Modal, Button } from "antd";
import moment from 'moment';
import {
    useGetPendingOrderMutation,
    useApproveOrderMutation,
    useDenyOrderMutation
} from "../../redux/slices/productSlice";
import Swal from "sweetalert2";

const SinglePendingOrders = () => {
    const [getPendingOrder, { isPendingOrderLoading }] = useGetPendingOrderMutation();
    const [orderData, setorderData] = useState([]);
    const [orderTotal, setorderTotal] = useState(0);
    const [approveOrder, { isLoading: isApproveOrderLoading }] = useApproveOrderMutation();
    const [denyOrder, { isLoading: isDenyOrderLoading }] = useDenyOrderMutation();
    const [recordId, setRecordId] = useState(null);
    const [pendingPerPage, setPendingPerPage] = useState(10);
    const [pendingPage, setPendingPage] = useState(1);
    const [detailModal, setDetailModal] = useState(false);
    const [patientDetails, setPatientDetails] = useState();
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);

    useEffect(() => {
        let query = `?per_page=${pendingPerPage}&page=${pendingPage}&type=single`;
        if (sortField && sortOrder) {
            query += `&sort_by=${sortField}&sort_direction=${sortOrder}`;
        }
        getOrderData(query)
    }, [pendingPerPage, pendingPage, sortField, sortOrder]);

    const getOrderData = (query) => {
        getPendingOrder(query)
            .then((res) => {
                setorderData(res?.data?.data?.data);
                setorderTotal(res?.data?.data?.total);
            })
            .catch((err) => {
                console.error("err", err);
            });
    }

    const handleOrderData = (data) => {
        setRecordId(data.id);
        approveOrder({ orderId: data.id })
            .then((res) => {
                Swal.fire({
                    title: "Order Approved Successfully",
                    icon: "success",
                });
                const query = `?per_page=${10}&page=${1}&type=single`;
                getOrderData(query);
            })
            .catch((err) => {
                console.warn({ err });
            })
            .finally(() => {
                setRecordId(null);
            });
    };

    const handleOrderDeny = (data) => {
        setRecordId(data.id);
        Swal.fire({
            title: "Add reason",
            text: "Add reason for order deny.",
            input: "textarea",
            showCancelButton: true,
            confirmButtonText: "Proceed",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                let formData = {
                    order_id: data?.id,
                    denial_reason: result?.value ? result?.value : "",
                };
                denyOrder(formData)
                    .then((response) => {
                        Swal.fire({
                            title: "Order Denied Successfully",
                            text: `${response?.data?.message}`,
                            icon: "success",
                        });
                        const query = `?per_page=${10}&page=${1}&type=single`;
                        getOrderData(query).catch((err) => {
                            console.error("err", err);
                        });
                    })
                    .catch((err) => {
                        const query = `?per_page=${10}&page=${1}&type=single`;
                        getOrderData(query);
                        console.warn({ err });
                    })
                    .finally(() => {
                        setRecordId(null);
                    });
            } else if (result.isDismissed) {
                console.log("Order denial was canceled.");
                setRecordId(null);
            }
        });
    };

    const generatePageSizeOptions = (total) => {
        const options = [];
        const maxPageSize = Math.min(total, 100);
        for (let i = 5; i <= maxPageSize; i += 5) {
            options.push(i);
        }
        if (total > 5 && !options.includes(10)) {
            options.push(10);
        }

        if (total > 10 && !options.includes(15)) {
            options.push(15);
        }
        return options.sort((a, b) => a - b);
    };

    const pageSizeOptions = generatePageSizeOptions(orderTotal);


    const ActionLoading = isApproveOrderLoading || isDenyOrderLoading;

    const Patientdetailscolumn = [
        {
            title: "S.No.",
            key: "serial",
            render: (_, __, index) => (pendingPage - 1) * pendingPerPage + index + 1,
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: true,
            render: ((text, record) => (
                <>
                    <span>{`${record?.data?.order?.patient?.firstName} ${record?.data?.order?.patient?.lastName}`}</span>
                </>
            ))
        },
        {
            title: "Order By",
            dataIndex: "order_by",
            key: "order_by",
            sorter: true,
            render: ((text, record) => (
                <>
                    <span>{record.order_by}</span>
                </>
            ))
        },
        {
            title: "Date Last Ordered",
            dataIndex: "dateLastOrdered",
            key: "dateLastOrdered",
            sorter: true,
            render: ((text, record) => (
                <>
                    <span>{moment(record.created_at).format('MM/DD/YY hh:mm:ss A')}</span>
                </>
            ))
        },
        {
            title: "Order Quantity",
            dataIndex: "orderQuantity",
            key: "orderQuantity",
            sorter: true,
            render: ((text, record) => (
                <>
                    <span>{record?.data?.order?.rxs[0]?.quantity ? record?.data?.order?.rxs[0]?.quantity : "N/A"}</span>
                </>
            ))
        },
        {
            title: "Delivery Address",
            dataIndex: "deliveryAddress",
            key: "deliveryAddress",
            sorter: true,
            render: ((text, record) => (
                <>
                    <span>{record?.data?.order?.new_shipping_address ? record?.data?.order?.new_shipping_address : record?.shipping?.addressLine1 ? record?.shipping?.addressLine1 : "N/A"}</span>
                </>
            ))
        },
        {
            title: "Order Details",
            dataIndex: "info",
            key: "info",
            render: ((text, record) => (
                <>
                    <Button className="craete-order-button" onClick={() => { setDetailModal(true); setPatientDetails(record) }}>
                        View Details
                    </Button>
                </>
            ))
        },
        {
            title: "Update Order",
            dataIndex: "update_order",
            key: "update_order",
            render: ((text, record) => {
                return (
                    <div className='action-button'>

                        {ActionLoading && recordId === record?.id ? (
                            <Spin />
                        ) : (
                            <>
                                <button className='approve-button' onClick={() => handleOrderData(record)}>
                                    {'Approve'}
                                </button>
                                <button className='deny-button' onClick={() => handleOrderDeny(record)}>
                                    {'Deny'}
                                </button>
                            </>
                        )}
                    </div>
                );
            })
        },
    ];

    const onPageSizeChange = (current, size) => {
        setPendingPerPage(size);
        setPendingPage(current);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter.order) {
            setSortField(sorter.field);
            setSortOrder(sorter.order === "ascend" ? "asc" : "desc");
        } else {
            setSortField(null);
            setSortOrder(null);
        }
    };


    return (
        <>
            <div className="wrapper orderWrapper">
                <div className="container-fluid">
                    <div className="row mb-4">
                        <div className="col-md-12">
                            <div className="titleBar d-flex justify-content-between">
                                <h4>Single Pending Orders</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mb-5">
                        <div className="card tableCard">
                            <div className="card-body">
                                <div className="tableWrapper registered-user-table">
                                    <Table
                                        columns={Patientdetailscolumn}
                                        dataSource={isPendingOrderLoading ? [] : orderData}
                                        pagination={{
                                            current: pendingPage,
                                            pageSize: pendingPerPage,
                                            total: orderTotal,
                                            onChange: (page) => setPendingPage(page),
                                            onShowSizeChange: onPageSizeChange,
                                            showSizeChanger: true,
                                            pageSizeOptions: pageSizeOptions,
                                            showQuickJumper: true,
                                        }}
                                        bordered
                                        className="mb-4 customOrderTable productTable"
                                        loading={isPendingOrderLoading}
                                        scroll={{ x: 'auto' }}
                                        onChange={handleTableChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                title="Order Details"
                centered
                open={detailModal}
                onOk={() => { setDetailModal(false); setPatientDetails() }}
                onCancel={() => { setDetailModal(false); setPatientDetails() }}
                className="bulk-order-modal"
                footer={false}
                width={700}
            >
                <div className="tableWrapper registered-user-table mt-4">
                    <div className='row'>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Patient Name:</label> {patientDetails?.data?.order?.patient?.firstName + ' ' + patientDetails?.data?.order?.patient?.lastName ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Drug Name:</label> {patientDetails?.data?.order?.rxs[0]?.drugName ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Drug Strength:</label> {patientDetails?.data?.order?.rxs[0]?.drugStrength ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Drug Form:</label> {patientDetails?.data?.order?.rxs[0]?.drugForm ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Quantity Unit:</label> {patientDetails?.data?.order?.rxs[0]?.quantityUnits ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Amount:</label> ${patientDetails?.amount ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Clinical Statement:</label> {patientDetails?.data?.order?.rxs[0]?.clinicalDifferenceStatement ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Drug Directions:</label> {patientDetails?.data?.order?.rxs[0]?.directions ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Patient Address:</label> {patientDetails?.data?.order?.patient?.address1 ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Shipping Address:</label> {patientDetails?.data?.order?.new_shipping_address ? patientDetails?.data?.order?.new_shipping_address : patientDetails?.shipping?.addressLine1 ?? "N/A"}</div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default SinglePendingOrders;
