import React, { useState, useEffect } from 'react';
import { Table, Input, Select, Button } from "antd";
import moment from 'moment';
import {
    useGetAppointmentMutation
} from "../../redux/slices/patientSlice";

const { Option } = Select;

const Appointment = () => {
    const [getAppointment, { isLoading }] = useGetAppointmentMutation();
    const [appointmentPage, setApponmtmentPage] = useState(1);
    const [appointmentPerPage, setApponmtmentPerPage] = useState(10);
    const [appointmentTotal, setAppointmentTotal] = useState(0);
    const [filteredAppointmentTotal, setFilteresAppointmentTotal] = useState(0);
    const [filteredAppointmentData, setFilteredAppointmentData] = useState([]);
    const [search, setSearch] = useState('');
    const [selectedService, setSelectedService] = useState(null);
    const [lastOrderday, setLastOrderday] = useState(null);
    const [last_visit_days, setLastVisit] = useState(null);
    const [disableFilter, setDisableFilter] = useState(false);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [debouncedSearch, setDebouncedSearch] = useState("");

    const getAppointmentList = async (value) => {
        try {
            let res = await getAppointment(value);
            setFilteredAppointmentData(res?.data?.data)
            setAppointmentTotal(res?.data?.total)
            setFilteresAppointmentTotal(res?.data?.total)
            setDisableFilter(false);
        }
        catch (err) {
            setDisableFilter(false);
            console.error(err);
        }
    }

    useEffect(() => {
        let query = `?per_page=${appointmentPerPage}&page=${appointmentPage}`;
        if (search) query += `&search=${search}`;
        if (selectedService) query += `&service=${selectedService}`;
        if (last_visit_days) query += `&days=${last_visit_days ? last_visit_days : ""}`;
        if (sortField && sortOrder) {
            query += `&sort_by=${sortField}&sort_direction=${sortOrder}`;
        }
        getAppointmentList(query);
    }, [appointmentPerPage, appointmentPage, search, sortOrder, sortField]);


    const onPageSizeChange = (current, size) => {
        setApponmtmentPerPage(size);
        setApponmtmentPage(1);
    };

    const generatePageSizeOptions = (total) => {
        const options = [];
        const maxPageSize = Math.min(total, 100);

        for (let i = 5; i <= maxPageSize; i += 5) {
            options.push(i);
        }
        if (total > 5 && !options.includes(10)) {
            options.push(10);
        }

        if (total > 10 && !options.includes(15)) {
            options.push(15);
        }
        return options.sort((a, b) => a - b);
    };

    const pageSizeOptions = generatePageSizeOptions(appointmentTotal);

    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter.order) {
            setSortField(sorter.field);
            setSortOrder(sorter.order === "ascend" ? "asc" : "desc");
        } else {
            setSortField(null);
            setSortOrder(null);
        }
    };

    const tableColumns = [
        {
            title: "S.No",
            key: "serial_number",
            render: (text, record, index) => {
                const currentPage = appointmentPage;
                const pageSize = appointmentPerPage;
                return <span>{(currentPage - 1) * pageSize + index + 1}</span>;
            },
        },
        {
            title: "Patient Name",
            dataIndex: "first_name",
            key: "first_name",
            sorter: true,
            // sorter: (a, b) => a.patient_name.length - b.patient_name.length,
            render: (text, record) => (
                <>
                    <span>{record.patient_name ? record.patient_name : "-"}</span>
                </>
            )
        },
        {
            title: "Booking",
            dataIndex: "online_versus_inhouse",
            key: "online_versus_inhouse",
            sorter: true,
            // sorter: (a, b) => a.booked.length - b.booked.length,
            render: (text, record) => (
                <>
                    <span>{record?.online_versus_inhouse}</span>
                </>
            )
        },
        {
            title: "Event Type",
            dataIndex: "event_type",
            key: "event_type",
            sorter: true,
            // sorter: (a, b) => a.event_type.length - b.event_type.length,
            render: (text, record) => (
                <>
                    <span>{record?.event_type}</span>
                </>
            )
        },
        {
            title: "Service Title",
            dataIndex: "service_title",
            key: "service_title",
            sorter: true,
            // sorter: (a, b) => a.service_title.length - b.service_title.length,
            render: (text, record) => (
                <>
                    <span>{record?.service_title}</span>
                </>
            )
        },
        {
            title: "Category",
            dataIndex: "service_category",
            key: "service_category",
            sorter: true,
            // sorter: (a, b) => a.service_category.length - b.service_category.length,
            render: (text, record) => (
                <>
                    <span>{record?.service_category}</span>
                </>
            )
        },
        {
            title: "Booking Time",
            dataIndex: "booktime",
            key: "booktime",
            sorter: true,
            // sorter: (a, b) => a.booktime.length - b.booktime.length,
            render: (text, record) => (
                <>
                    <span>{moment(record.appointment_created_date).format('MM/DD/YY')}</span>
                </>
            )
        },
        {
            title: "Next Appointment",
            dataIndex: "upcoming_appointment_date",
            key: "upcoming_appointment_date",
            sorter: true,
            // sorter: (a, b) => a.booktime.length - b.booktime.length,
            render: (text, record) => (
                <>
                    <span>{record?.upcoming_appointment_date ? moment(record?.upcoming_appointment_date).format('MM/DD/YY') : "N/A"}</span>
                </>
            )
        },
        {
            title: "Last Updated",
            dataIndex: "lastseen",
            key: "lastseen",
            sorter: true,
            // sorter: (a, b) => a.lastseen.length - b.lastseen.length,
            render: (text, record) => (
                <>
                    <span>{moment(record.updated_at).format('MM/DD/YY')}</span>
                </>
            )
        },
        {
            title: "Status",
            dataIndex: "booking_status",
            key: "booking_status",
            sorter: true,
            render: (text, record) => (
                <>
                    <span>{record?.booking_status}</span>
                </>
            )
        },
    ];

    const handleAppointmentSearch = async (event) => {
        setApponmtmentPage(1);
        const value = event?.target?.value.trim();
        setSearch(value);
    };

    // useEffect(() => {
    //     setApponmtmentPage(1);
    //     if (search !== "") {
    //         const handler = setTimeout(() => {
    //             setDebouncedSearch(search);
    //         }, 1200);
    //         return () => clearTimeout(handler);
    //     } else {
    //         setDebouncedSearch("");
    //     }
    // }, [search]);

    const handledayChange = (day) => {
        setDisableFilter(true);
        setLastVisit(day);
        setLastOrderday(null)
        setApponmtmentPerPage(10)
        setApponmtmentPage(1)
        let query = `?days=${day}&per_page=${10}&page=${1}`
        if (debouncedSearch !== '') {
            query += `&search=${debouncedSearch}`
        }
        if (sortField && sortOrder) {
            query += `&sort_by=${sortField}&sort_direction=${sortOrder}`;
        }
        if (selectedService) {
            query += `&service=${selectedService}`
        }
        getFilteredAppointmentData(query)
    };

    const handleSelectService = (data) => {
        setDisableFilter(true);
        setSelectedService(data);
        setApponmtmentPerPage(10);
        setApponmtmentPage(1);
        let query = `?days=${last_visit_days ? last_visit_days : ''}&per_page=${10}&page=${1}`
        if (data !== '') {
            query += `&service=${data}`
        }
        if (sortField && sortOrder) {
            query += `&sort_by=${sortField}&sort_direction=${sortOrder}`;
        }
        getAppointmentList(query);
    }

    const getFilteredAppointmentData = (value) => {
        getAppointment(value).then((res) => {
            setFilteredAppointmentData(res?.data?.data)
            setFilteresAppointmentTotal(res?.data?.total);
            setDisableFilter(false);
        }).catch((error) => {
            setDisableFilter(false);
            console.error("Error fetching filtered patients:", error);
        });
    }

    const resetFilters = () => {
        setSelectedService(null);
        setLastVisit(null);
        setSearch('');
        setDebouncedSearch('');
        setApponmtmentPage(1);
        setApponmtmentPerPage(10);
        setSortField(null);
        setSortOrder(null);
        let query = `?per_page=${appointmentPerPage}&page=${1}`;
        getAppointmentList(query);
    }

    return (
        <div className="wrapper">
            <div className="container-fluid">
                <div className="row mb-5">
                    <div className="col-md-12">
                        <div className="titleBar titleRow">
                            <h4>Appointments List</h4>
                            <div className="d-flex justify-content-between align-items-end" style={{ paddingRight: '1.5rem' }}>
                                <div className="formItem me-3">
                                <label htmlFor="lastVisitSelect" className="dynamicLabel">Last Visited</label>
                                    <Select
                                        placeholder="Select Last Visit"
                                        className="formSelect w-100"
                                        onChange={(value) => {
                                            handledayChange(value);
                                        }}
                                        disabled={disableFilter}
                                        value={last_visit_days}
                                    >
                                        <Option value="7">7 days</Option>
                                        <Option value="30">30 days</Option>
                                        <Option value="45">45 days</Option>
                                        <Option value="60">60 days</Option>
                                        <Option value="90">90 days</Option>
                                        <Option value="120">120 days</Option>
                                    </Select>
                                </div>
                                <div className="formItem" >
                                <label htmlFor="lastVisitSelect" className="dynamicLabel">Select Service</label>
                                    <Select
                                        placeholder="Select Service"
                                        className="formSelect w-100"
                                        value={selectedService}
                                        onChange={(value) => {
                                            handleSelectService(value);
                                        }}
                                        disabled={disableFilter}
                                    >
                                        <Option value="semaglutide">Semaglutide</Option>
                                        <Option value="tirzepatide">Tirzepatide</Option>
                                    </Select>
                                </div>
                                <div className="formItem">
                                <label htmlFor="lastVisitSelect" className="dynamicLabel ms-3">Search Here</label>
                                    <Input
                                        value={search}
                                        placeholder="Search here.."
                                        onChange={handleAppointmentSearch}
                                        className="input_patient_search"
                                        style={{ padding: "0.7rem", marginLeft: '1rem', height:"49px" }}
                                        disabled={disableFilter}
                                        allowClear
                                    />
                                </div>
                                <div className="resetFiltersWrapper" style={{ display: 'flex', alignItems: 'center', marginLeft: '2rem' }}>
                                    <Button onClick={resetFilters}  style={{height:"49px"}} disabled={disableFilter}>Reset</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="tableWrapper">
                            <Table
                                className="registered-user-table"
                                columns={tableColumns}
                                dataSource={isLoading? [] : filteredAppointmentData}
                                pagination={
                                    (filteredAppointmentData?.length > 0)
                                        ? {
                                            current: appointmentPage,
                                            pageSize: appointmentPerPage,
                                            total: filteredAppointmentTotal,
                                            onChange: (page) => setApponmtmentPage(page),
                                            onShowSizeChange: onPageSizeChange,
                                            showSizeChanger: true,
                                            showQuickJumper: true,
                                            pageSizeOptions: pageSizeOptions,
                                        }
                                        : false}
                                loading={isLoading}
                                onChange={handleTableChange}
                                showSorterTooltip={false}
                                scroll={{ x: 'auto' }}
                                bordered
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Appointment;
