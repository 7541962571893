import React from "react";
import { Tabs } from "antd";
import BulkPendingOrder from "./bulkPendingOrder";
import SinglePendingOrder from "./singlePendingOrder";

const PendingOrders = () => {

    const items = [
        {
            key: '1',
            label: 'Single Pending Orders',
            children: <SinglePendingOrder />,
        },
        {
            key: '2',
            label: 'Bulk Pending Orders',
            children: <BulkPendingOrder />,
        },

    ];


    return (
        <>
            <div className="wrapper orderWrapper">
                <div className="container-fluid">
                    <div className="row mb-4">
                        <div className="col-md-12">
                            <div className="titleBar d-flex justify-content-between">
                                <h4>Pending Orders</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mb-5">
                        <div className="card tableCard">
                            <div className="card-body">
                                <Tabs
                                    className="pending-order-tab"
                                    defaultActiveKey="1"
                                    items={items}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PendingOrders;
