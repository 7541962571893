import React, { useState } from "react";
import { Button, Form, Input, message, Upload, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined, PlusOutlined, PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { MdHomeFilled, MdArrowBack } from "react-icons/md";
import { useAddProductMutation } from "../../redux/slices/productSlice";
import Swal from 'sweetalert2'
import { MdRefresh } from "react-icons/md";
import { useSelector } from "react-redux";
const { TextArea } = Input;

const AddProducts = () => {
    const navigate = useNavigate();
    const userData = useSelector(
        (state) => state?.persistedReducer?.user
    );
    const [form] = Form.useForm();
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [showBox, setshowBox] = useState(false);
    const [addProduct, { isLoading: isaddProductLoading }] = useAddProductMutation();
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

        if (!isJpgOrPng) {
            message.error("You can only upload JPG/PNG file!");
        }

        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isLt2M) {
            message.error("Image must smaller than 2MB!");
        }

        return isJpgOrPng && isLt2M;
    };

    const handleImageChange = (info) => {
        if (info.fileList.length > 0) {
            if (info.file.status === "uploading") {
                setLoading(true);
                return;
            }

            if (info.file.status === "done") {
                getBase64(info.file.originFileObj, (url) => {
                    setLoading(false);
                    setImageUrl(url);
                });
            }
        } else {
            alert("no image uploaded");
        }
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: "none",
            }}
            type="button"
        >
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    const onFinish = async (values) => {
        const formData = new FormData();
        formData.append("category_id", values.category_id);
        formData.append("vendor_name", values.vendor_name);
        formData.append("company_name", values.company_name);
        formData.append("item_name", values.item_name);
        formData.append("size", values.size);
        formData.append("price", values.price);
        formData.append("in_stock", 1);
        formData.append("quantityoption", values.quantityoption);
        formData.append("low_stock_threshold", values.minimum_quantity ? values.minimum_quantity : 0);
        formData.append("user_id", userData?.id)
        if (values.expiry) {
            formData.append("expiry", values?.expiry);
        }

        if (values.quantity) {
            formData.append("quantity", values.quantity)
        }
        formData.append("sku", values.sku);
        formData.append("product_description", values.product_description);
        if (values.boxes) {
            formData.append('boxes', JSON.stringify(values.boxes))
        }
        if (values.image && values.image.file && values.image.file.originFileObj) {
            const file = values.image.file.originFileObj;
            formData.append("image", file);
        }
        const response = await addProduct(formData);
        if (response.error) {
            Swal.fire({
                title: response.error.data.message,
                icon: "error",
                confirmButtonText: "OK",
            });
            return;
        }
        const { message, product } = response.data;
        if (product) {
            Swal.fire({
                title: message,
                icon: "success",
            });
            form.resetFields();
            navigate("/allproducts")
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const handleFormChange = () => {
        const values = form.getFieldsValue();
        const allFieldsFilled =
            values.category_id &&
            values.vendor_name &&
            values.company_name &&
            values.item_name &&
            values.size &&
            values.price &&
            values.in_stock &&
            // values.quantity &&
            values.sku &&
            values.image;
        setIsButtonDisabled(!allFieldsFilled);
    };

    const handleGoHome = () => {
        navigate("/");
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleBoxChange = (val) => {
        if (val === 'box') {
            setshowBox(true);
        } else {
            setshowBox(false);
        }
    }

    return (
        <div className="wrapper">
            <div className="container-fluid">
                <div className="row mb-5">
                    <div className="col-md-12">
                        <div className="titleBar titleRow">
                            <h4>Add Product</h4>
                            <div className="buttonWrap">
                                <button
                                    type="button"
                                    className="btn backBtn"
                                    onClick={() => handleGoBack()}
                                >
                                    <MdArrowBack /> Back
                                </button>
                                <button
                                    type="button"
                                    className="btn homeBtn"
                                    onClick={() => handleGoHome()}
                                >
                                    <MdHomeFilled /> Go to Home
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="formWrapper">
                            <Form
                                form={form}
                                name="accountForm1"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                // onValuesChange={handleFormChange}
                                autoComplete="off"
                                layout="vertical"
                                initialValues={{
                                    category: "Select Category",
                                }}
                            >
                                <div className="row">
                                    <div className="col-md-4">
                                        <Form.Item
                                            label="Category"
                                            name="category_id"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select category!",
                                                },
                                            ]}
                                        >
                                            <Select className="formSelect" placeholder='Enter category Name' disabled={isaddProductLoading}>
                                                <Select.Option value={1}>Injectable</Select.Option>
                                                <Select.Option value={2}>Supplies</Select.Option>
                                                <Select.Option value={3}>Medications</Select.Option>
                                                <Select.Option value={4}>Skin Care</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-4">
                                        <Form.Item
                                            label="Vendor Name"
                                            name="vendor_name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter vendor name!",
                                                },
                                            ]}
                                        >
                                            <Input
                                            disabled={isaddProductLoading}
                                                className="formControl"
                                                placeholder="Enter vendor name"
                                            />
                                        </Form.Item>
                                    </div>

                                    <div className="col-md-4">
                                        <Form.Item
                                            label="Company Name"
                                            name="company_name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter company name!",
                                                },
                                            ]}
                                        >
                                            <Input
                                            disabled={isaddProductLoading}
                                                className="formControl"
                                                placeholder="Enter company name"
                                            />
                                        </Form.Item>
                                    </div>

                                    <div className="col-md-4">
                                        <Form.Item
                                            label="Item Name"
                                            name="item_name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter item name!",
                                                },
                                            ]}
                                        >
                                            <Input
                                            disabled={isaddProductLoading}
                                                className="formControl"
                                                placeholder="Enter item name"
                                            />
                                        </Form.Item>
                                    </div>

                                    <div className="col-md-4">
                                        <Form.Item
                                            label="Size"
                                            name="size"
                                        >
                                            <Input
                                            disabled={isaddProductLoading}
                                                type="number"
                                                className="formControl"
                                                placeholder="Enter size here"
                                                onKeyPress={(e) => {
                                                    if (!/[0-9]/.test(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />
                                        </Form.Item>
                                    </div>

                                    <div className="col-md-4">
                                        <Form.Item
                                            label="Price"
                                            name="price"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter price!",
                                                },
                                            ]}
                                        >
                                            <Input
                                            disabled={isaddProductLoading}
                                                className="formControl"
                                                placeholder="Enter price here"
                                                rules={[
                                                    {
                                                        validator: (_, value) =>
                                                            value && parseFloat(value) > 0
                                                                ? Promise.resolve()
                                                                : Promise.reject(new Error("Price must be greater than 0.")),
                                                    },
                                                ]}
                                                maxLength={12}
                                                onKeyPress={(e) => {
                                                    const inputValue = e.currentTarget.value;
                                                    if (!/^\d$/.test(e.key) && e.key !== ".") {
                                                        e.preventDefault();
                                                    }
                                                    if (inputValue.includes(".") && e.key === ".") {
                                                        e.preventDefault();
                                                    }
                                                    if (inputValue === "" && e.key === "0") {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onPaste={(e) => {
                                                    const paste = e.clipboardData.getData("text");
                                                    if (!/^(?!0\d)\d*(\.\d{0,2})?$/.test(paste)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />

                                        </Form.Item>
                                    </div>

                                    {/* <div className="col-md-4">
                                        <Form.Item
                                            label="In Stock"
                                            name="in_stock"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter stock!",
                                                },
                                            ]}
                                        >
                                            <Select className="formSelect" placeholder='InStock'>
                                                <Select.Option value={1}>Yes</Select.Option>
                                                <Select.Option value={0}>No</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </div> */}
                                    <div className="col-md-4">
                                        <Form.Item
                                            label="Expiry Date"
                                            name="expiry"
                                        >
                                            <Input disabled={isaddProductLoading} type="date" className="add-product-date-picker" />
                                        </Form.Item>
                                    </div>

                                    <div className="col-md-4">
                                        <Form.Item
                                            label="Select Quantity/box"
                                            name="quantityoption"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please Select any of the option!",
                                                },
                                            ]}
                                        >
                                            <Select className="formSelect" onChange={handleBoxChange} placeholder="Select Box/Quantity" disabled={isaddProductLoading}>
                                                <Select.Option value='box'>Box</Select.Option>
                                                <Select.Option value='quantity'>Quantity</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </div>

                                    <div className="col-md-4">
                                        <Form.Item
                                            label="SKU"
                                            name="sku"
                                        >
                                            <Input
                                            disabled={isaddProductLoading}
                                                className="formControl"
                                                placeholder="Enter your SKU"
                                            />
                                        </Form.Item>
                                    </div>
                                    {showBox ? (
                                        <Form.List name="boxes" initialValue={[{}]}>
                                            {(fields, { add, remove }) => {
                                                // Automatically add the first box when showBox is true
                                                if (fields.length === 0) {
                                                    add();
                                                }

                                                return (
                                                    <>
                                                        {fields.map(({ key, name, ...restField }, index) => (
                                                            <React.Fragment key={key}>
                                                                <div className="col-md-4">
                                                                    <Form.Item
                                                                        {...restField}
                                                                        label="Units"
                                                                        name={[name, "units"]}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: "Please input your units!",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Select className="formSelect" placeholder="Units" disabled={isaddProductLoading}>
                                                                            <Select.Option value="litres">Litres</Select.Option>
                                                                            <Select.Option value="millitres">Millilitres</Select.Option>
                                                                        </Select>
                                                                    </Form.Item>
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <Form.Item
                                                                        {...restField}
                                                                        label="Box quantity"
                                                                        name={[name, "boxquantity"]}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: "Please input your box quantity!",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                        disabled={isaddProductLoading}
                                                                            className="formControl"
                                                                            placeholder="Enter your Box Quantity"
                                                                            type="number"
                                                                            onKeyPress={(e) => {
                                                                                if (!/[0-9]/.test(e.key)) {
                                                                                    e.preventDefault();
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Form.Item>
                                                                </div>

                                                                <div className="col-md-4 addCol">
                                                                    <Form.Item
                                                                        {...restField}
                                                                        label="Disposable"
                                                                        name={[name, "disposable"]}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: "Please input your disposable!",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                        disabled={isaddProductLoading}
                                                                            className="formControl"
                                                                            placeholder="Enter your Disposable"
                                                                        />

                                                                    </Form.Item>
                                                                    <Form.Item>
                                                                        <Button
                                                                            type="dashed"
                                                                            onClick={() => add()}
                                                                            icon={<PlusCircleOutlined />}
                                                                            className="addMoreBtn"
                                                                        >
                                                                        </Button>
                                                                    </Form.Item>

                                                                </div>

                                                                {index > 0 && (
                                                                    <div className="col-md-12">
                                                                        <Button
                                                                            type="danger"
                                                                            onClick={() => remove(name)}
                                                                            icon={<MinusCircleOutlined />}
                                                                            className="w-100 mb-3"
                                                                        >
                                                                            Remove
                                                                        </Button>
                                                                    </div>
                                                                )}
                                                            </React.Fragment>
                                                        ))}
                                                    </>
                                                );
                                            }}
                                        </Form.List>
                                    ) : (
                                        <div className="col-md-4">
                                            <Form.Item
                                                label="Quantity"
                                                name="quantity"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Enter quantity!",
                                                    },
                                                ]}
                                            >

                                                <Input
                                                disabled={isaddProductLoading}
                                                    className="formControl"
                                                    placeholder="Enter Quantity name"
                                                    type="text"
                                                    rules={[
                                                        {
                                                            validator: (_, value) =>
                                                                value && Number(value) > 0
                                                                    ? Promise.resolve()
                                                                    : Promise.reject(new Error("Quantity must be greater than 0 and not exceed 5 digits.")),
                                                        },
                                                    ]}
                                                    maxLength={5}
                                                    onKeyPress={(e) => {
                                                        const inputValue = e.currentTarget.value;
                                                        if (inputValue === "" && e.key === "0") {
                                                            e.preventDefault();
                                                        } else if (!/^\d$/.test(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onPaste={(e) => {
                                                        const paste = e.clipboardData.getData("text");
                                                        if (!/^[1-9]\d{0,4}$/.test(paste)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                />


                                            </Form.Item>
                                        </div>
                                    )}

                                    <div className="col-md-4">
                                        <Form.Item
                                            label="Minimum Quantity"
                                            name="minimum_quantity"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter minimum quantity!",
                                                },
                                            ]}
                                        >
                                            <Input
                                            disabled={isaddProductLoading}
                                                className="formControl"
                                                placeholder="Enter Minimum Quantity"
                                                rules={[
                                                    {
                                                        validator: (_, value) =>
                                                            value && Number(value) > 0
                                                                ? Promise.resolve()
                                                                : Promise.reject(new Error("Minimum Quantity must be greater than 0 and not exceed 5 digits.")),
                                                    },
                                                ]}
                                                maxLength={5}
                                                onKeyPress={(e) => {
                                                    const inputValue = e.currentTarget.value;
                                                    if (inputValue === "" && e.key === "0") {
                                                        e.preventDefault();
                                                    } else if (!/^\d$/.test(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onPaste={(e) => {
                                                    const paste = e.clipboardData.getData("text");
                                                    if (!/^[1-9]\d{0,4}$/.test(paste)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />
                                        </Form.Item>
                                    </div>



                                    <div className="col-md-4">
                                        <Form.Item
                                            label="Image"
                                            name="image"
                                            valuePropName="avatar"
                                        >
                                            <Upload
                                            disabled={isaddProductLoading}
                                                name="avatar"
                                                listType="picture-card"
                                                className="avatar-uploader w-100"
                                                showUploadList={false}
                                                beforeUpload={beforeUpload}
                                                onChange={handleImageChange}
                                                customRequest={({ file, onSuccess }) => {
                                                    setTimeout(() => {
                                                        onSuccess("ok");
                                                    }, 0);
                                                }}
                                            >
                                                {imageUrl ? (
                                                    <img
                                                        src={imageUrl}
                                                        alt="avatar"
                                                        style={{
                                                            width: "100%",
                                                            height: "100%"
                                                        }}
                                                    />
                                                ) : (
                                                    uploadButton
                                                )}
                                            </Upload>
                                        </Form.Item>
                                    </div>


                                    <div className="col-md-12">
                                        <Form.Item
                                            label="Product Description"
                                            name="product_description"
                                        >
                                            <TextArea
                                                className="formControl"
                                                placeholder="Enter description"
                                                rows={3}
                                            />
                                        </Form.Item>
                                    </div>
                                    <Form.Item className="col-md-2 text-center my-5">
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="submitBtn w-100"
                                            disabled={isaddProductLoading}
                                            icon={<MdRefresh style={{ fontSize: "20px", marginRight: "0.5rem" }} />}
                                            loading={isaddProductLoading}
                                        >
                                            Create Product
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddProducts;
