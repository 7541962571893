import React from "react";
import { IoMdHeart } from "react-icons/io"; 

const Footer = () => {
    return (
        <div className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="copywriteFooter">
                            © {new Date().getFullYear()} All rights reserved, made with <IoMdHeart style={{color: '#e31b23'}} /> by{" "}
                            <a href="https://www.aleaitsolutions.com/" className="footerLink" target="_blank" rel="noopener noreferrer">
                                AleaIt Solutions
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
