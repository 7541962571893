import React, { useEffect, useState } from 'react';
import { Table, Input } from "antd";
import {
    useGetInventoryLogsMutation
} from "../../redux/slices/productSlice";
import Autocomplete from 'react-autocomplete';
import { useNavigate } from "react-router-dom";
import moment from 'moment';


export default function InventoryChangeLogs() {
    const navigate = useNavigate();
    const [getInventoryLogs, { isLoading }] = useGetInventoryLogsMutation();
    const [inventoryData, setInventoryData] = useState([])
    const [inventoryPerPage, setInventoryPerPage] = useState(10)
    const [inventoryPage, setInventoryPage] = useState(1)
    const [inventoryTotal, setInventoryTotal] = useState(0)
    const [productSearch, setProductSearch] = useState('')
    const [userSearch, setUserSearch] = useState('')
    const [allUsers, setAllUsers] = useState([])
    const [allProducts, setAllProducts] = useState([]);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);

    useEffect(() => {
        let query = `?per_page=${inventoryPerPage}&page=${inventoryPage}`
        if (productSearch !== '') {
            query += `&product_name=${productSearch}`
        }
        if (userSearch !== '') {
            query += `&user_name=${userSearch}`
        }
        if (sortField && sortOrder) {
            query += `&sort_by=${sortField}&sort_direction=${sortOrder}`;
        }
        getInventoryLogs(query).then((response) => {
            setAllUsers(Array.from(new Set(response?.data?.data?.map(item => item?.user?.name) || [])));
            setAllProducts(Array.from(new Set(response?.data?.data?.map(item => item?.product?.item_name) || [])));
            setInventoryData(response?.data?.data)
            setInventoryTotal(response?.data?.pagination?.total)
        }).catch((error) => {
            console.error({ error })
        })
    }, [inventoryPage, inventoryPerPage, productSearch, userSearch, sortField, sortOrder])


    const filteredUsers = allUsers.filter(item =>
        item?.toLowerCase()?.includes(userSearch?.toLowerCase())
    );
    const filteredProducts = allProducts.filter(item =>
        item?.toLowerCase()?.includes(productSearch?.toLowerCase())
    );

    const tableColumns = [
        {
            title: "S.No",
            dataIndex: "sno",
            key: "sno",
            render: (_, record, index) => (inventoryPage - 1) * inventoryPerPage + index + 1,

        },
        {
            title: "Product Name",
            dataIndex: "item_name",
            key: "item_name",
            sorter: true,
            render: (text, record) => (
                <span>
                    {record?.product?.item_name}
                </span>
            ),

        },
        {
            title: "User Name",
            dataIndex: "user_name",
            key: "user_name",
            sorter: true,
            render: (text, record) => (
                <span>
                    {record?.user?.user_name}
                </span>
            ),

        },
        {
            title: "Description ",
            dataIndex: "description ",
            key: "description ",
            sorter: true,
            render: (text, record) => (
                <span>
                    {`${record?.description} ${moment(record.product?.updated_at).format('MM/DD/YY hh:mm:ss A')}`}

                </span>
            ),
        },
        {
            title: "Previous Quantity",
            dataIndex: "quantity_before",
            key: "quantity_before",
            sorter: true,
            render: (text, record) => (
                <span>
                    {record?.quantity_before}
                </span>
            ),
        },
        {
            title: "Updated Quantity",
            dataIndex: "quantity_after",
            key: "quantity_after",
            sorter: true,
            render: (text, record) => (
                <span>
                    {record?.quantity_after}
                </span>
            ),

        },
    ];

    const generatePageSizeOptions = (total) => {
        const options = [];
        const maxPageSize = Math.min(total, 100);

        for (let i = 5; i <= maxPageSize; i += 5) {
            options.push(i);
        }
        if (total > 5 && !options.includes(10)) {
            options.push(10);
        }

        if (total > 10 && !options.includes(15)) {
            options.push(15);
        }
        return options.sort((a, b) => a - b);
    };

    const pageSizeOptions = generatePageSizeOptions(inventoryTotal);

    const onPageSizeChange = (current, size) => {
        setInventoryPerPage(size);
        setInventoryPage(1);
    };

    const handleProductDetails = (id) => {
        navigate("/product-details", {
            state: { productId: id },
        });
    };

    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter.order) {
            setSortField(sorter.field);
            setSortOrder(sorter.order === "ascend" ? "asc" : "desc");
        } else {
            setSortField(null);
            setSortOrder(null);
        }
    };

    return (
        <div className="wrapper productCatalogWrapper">
            <div className="container-fluid">
                <div className="row mb-5">
                    <div className="col-md-12">
                        <div className="titleBar titleRow">
                            <h4>Inventory Change Logs</h4>
                            <div className="rightWrapper">
                                <div className="inputContainer" style={{ marginBottom: "0px" }}>
                                    <label htmlFor="lastVisitSelect" className="dynamicLabel">Search Product</label>
                                    <Input
                                        style={{ height: "50px" }}
                                        placeholder="Search Product"
                                        onChange={(e) => setProductSearch(e?.target?.value)}
                                        className="homesearchInput titleRowInput"
                                    />
                                </div>
                                <div className="inputContainer" style={{ marginBottom: "0px" }}>
                                    <label htmlFor="lastVisitSelect" className="dynamicLabel">Search User</label>
                                    <Input
                                        style={{ height: "50px" }}
                                        placeholder="Search User"
                                        onChange={(e) => setUserSearch(e?.target?.value)}
                                        className="homesearchInput titleRowInput"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="tableWrapper">
                            <Table
                                className="productTable"
                                columns={tableColumns}
                                dataSource={isLoading ? [] : inventoryData}
                                onChange={handleTableChange}
                                pagination={{
                                    current: inventoryPage,
                                    pageSize: inventoryPerPage,
                                    total: inventoryTotal,
                                    onChange: (page) => setInventoryPage(page),
                                    onShowSizeChange: onPageSizeChange,
                                    showSizeChanger: true,
                                    showQuickJumper: true,
                                    pageSizeOptions: pageSizeOptions,
                                }}
                                loading={isLoading}
                                showSorterTooltip={false}
                                bordered
                                scroll={{ x: 'auto' }}
                                onRow={(record) => ({
                                    onClick: () => handleProductDetails(record?.product?.id)
                                })}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
