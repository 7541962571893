import React, { useState, useEffect } from "react";
import { Table, Spin, Modal } from "antd";
import { FaUserCircle } from "react-icons/fa";
import {
    FaHospitalUser,
    FaHandHoldingMedical,
    FaWheelchair,
} from "react-icons/fa6";
import { TbReportMedical } from "react-icons/tb";
import { BiLogOutCircle } from "react-icons/bi";
import { logout } from "../../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    useGetAppointmentMutation,
    useGetPatientStatisticsMutation,
} from "../../redux/slices/patientSlice";
import { useGetProductMutation } from "../../redux/slices/productSlice";
import { FcShop } from "react-icons/fc";
import { useSelector } from "react-redux";

const Dashboard = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userData = useSelector(
        (state) => state?.persistedReducer?.user
    );
    const [getProduct, { isLoading: isProductLoading }] = useGetProductMutation();
    const [getAppointment, { isLoading: isAppointmentLoading }] =
        useGetAppointmentMutation();
    const [getPatientStastic, { isLoading: isPatientStatisticsLoading }] =
        useGetPatientStatisticsMutation();
    const [upcomingPatient, setupcomingPatient] = useState([]);
    const [upcomingPage, setUpcomingPage] = useState(1);
    const [upcomingPerPage, setUpcomingPerPage] = useState(5);
    const [upcomingPatientTotal, setupcomingPatientTotal] = useState(0);
    const [patientStastics, setPatientStastics] = useState();
    const [expiredProducts, setExpiredProducts] = useState();
    const [expiredProductsTotal, setExpiredProductsTotal] = useState();
    const [expiredPage, setExpiredPage] = useState(1);
    const [expiredPerPage, setExpiredPerPage] = useState(5);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [logoutClicked, setLogoutClicked] = useState(false);
    const [injectible, setInjectible] = useState([]);
    const [injectiblePage, setInjectiblePage] = useState(1);
    const [injectiblePerPage, setInjectiblePerPage] = useState(5);
    const [totalInjectiblePage, setTotalInjectiblePage] = useState(0);
    const [medications, setMedications] = useState([]);
    const [totalMedicationsPage, setTotalMedicationsPage] = useState(0);
    const [medicationsPage, setMedicationsPage] = useState(1);
    const [medicationsPerPage, setMedicationsPerPage] = useState(5);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortExpiredOrder, setSortExpiredOrder] = useState(null);
    const [sortExpiredField, setSortExpiredField] = useState(null);
    const [lowInjectiblesOrder, setlowInjectiblesOrder] = useState(null);
    const [lowInjectiblesFeild, setlowInjectiblesField] = useState(null);
    const [lowMedicationOrder, setlowMedicationOrder] = useState(null);
    const [lowMedicationField, setlowMedicationField] = useState(null);

    const getPatientStastics = async () => {
        getPatientStastic()
            .then((response) => {
                setPatientStastics(response?.data);
            })
            .catch((err) => {
                console.error({ err });
            });
    };

    useEffect(() => {
        const hasSeenModal = sessionStorage.getItem("hasSeenModal");

        if (!hasSeenModal) {
            setIsModalOpen(true);
            sessionStorage.setItem("hasSeenModal", "true");
        }
        getPatientStastics();
    }, []);

    const getExpiredProducts = async (value) => {
        const res = await getProduct(value);
        setExpiredProducts(res?.data?.data);
        setExpiredProductsTotal(res?.data?.total);
    };
    const getUpcomingProspects = async (value) => {
        const res = await getAppointment(value);
        setupcomingPatient(res?.data?.data);
        setupcomingPatientTotal(res?.data?.total);
    };

    const getInjectibleProducts = async (value) => {
        const res = await getProduct(value);
        setInjectible(res?.data?.data);
        setTotalInjectiblePage(res?.data?.total);
    };

    const getMedicationProducts = async (value) => {
        const res = await getProduct(value);
        setMedications(res?.data?.data);
        setTotalMedicationsPage(res?.data?.total);
    };

    useEffect(() => {
        let query = `?per_page=${expiredPerPage}&page=${expiredPage}&expired=1`;
        if (sortExpiredField && sortExpiredOrder) {
            query += `&sort_by=${sortExpiredField}&sort_direction=${sortExpiredOrder}`;
        }
        getExpiredProducts(query);
    }, [expiredPage, expiredPerPage, sortExpiredField, sortExpiredOrder]);

    useEffect(() => {
        let query = `?per_page=${upcomingPerPage}&page=${upcomingPage}`;
        if (sortField && sortOrder) {
            query += `&sort_by=${sortField}&sort_direction=${sortOrder}`;
        }
        getUpcomingProspects(query);
    }, [upcomingPage, upcomingPerPage, sortField, sortOrder]);

    useEffect(() => {
        let query = `?per_page=${injectiblePerPage}&page=${injectiblePage}&low_stock=true&category_id=[1]`;
        if (lowInjectiblesFeild && lowInjectiblesOrder) {
            query += `&sort_by=${lowInjectiblesFeild}&sort_direction=${lowInjectiblesOrder}`;
        }
        getInjectibleProducts(query);
    }, [injectiblePage, injectiblePerPage, lowInjectiblesFeild, lowInjectiblesOrder]);

    useEffect(() => {
        let query = `?per_page=${medicationsPerPage}&page=${medicationsPage}&low_stock=true&category_id=[3]`;
        if (lowMedicationField && lowMedicationOrder) {
            query += `&sort_by=${lowMedicationField}&sort_direction=${lowMedicationOrder}`;
        }
        getMedicationProducts(query);
    }, [medicationsPage, medicationsPerPage, lowMedicationField, lowMedicationOrder]);


    const handlePatientDetails = (key) => {
        navigate("/patientappointment", {
            state: { patientId: key },
        });
    }

    const handleProductDetails = (id) => {
        navigate("/product-details", {
            state: { productId: id },
        });
    };

    const LowStockMedicationsColumns = [
        {
            title: "S.No.",
            dataIndex: "serial",
            key: "serial",
            render: (_, record, index) => (
                <>
                    {(upcomingPage - 1) * 10 + index + 1}
                </>
            ),
        },
        {
            title: "Product Name",
            dataIndex: "item_name",
            key: "item_name",
            sorter: true,
            render: (text, record) => (
                <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                            src={record.image}
                            alt={text}
                            style={{
                                width: 50,
                                height: 50,
                                marginRight: 20,
                                borderRadius: "5px",
                            }}
                        />
                        <span style={{ textTransform: "capitalize", fontWeight: 600 }}>
                            {record?.item_name}
                        </span>
                    </div>
                </>
            ),
        },
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
            sorter: true,
            render: (text, record) => (
                <>
                    <span>{record?.category?.name}</span>
                </>
            ),
        },
        {
            title: "Company Name",
            dataIndex: "company_name",
            key: "company_name",
            sorter: true,
            render: (text, record) => (
                <>
                    <span>{record?.company_name}</span>
                </>
            ),
        },
        {
            title: "Inventory Count",
            dataIndex: "quantity",
            key: "quantity",
            sorter: true,
            render: (text, record) => (
                <>
                    <span>{record?.quantity}</span>
                </>
            ),
        },

    ];

    const LowStockInjectiblesColumns = [
        {
            title: "S.No.",
            dataIndex: "serial",
            key: "serial",
            render: (_, record, index) => (
                <>
                    {(injectiblePage - 1) * 10 + index + 1}
                </>
            ),
        },
        {
            title: "Product Name",
            dataIndex: "item_name",
            key: "item_name",
            sorter: true,
            render: (text, record) => (
                <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                            src={record.image}
                            alt={text}
                            style={{
                                width: 50,
                                height: 50,
                                marginRight: 20,
                                borderRadius: "5px",
                            }}
                        />
                        <span style={{ textTransform: "capitalize", fontWeight: 600 }}>
                            {record?.item_name}
                        </span>
                    </div>
                </>

            ),
        },
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
            sorter: true,
            render: (text, record) => (
                <>
                    <span>{record?.category?.name}</span>
                </>
            ),
        },
        {
            title: "Company Name",
            dataIndex: "company_name",
            key: "company_name",
            sorter: true,
            render: (text, record) => (
                <>
                    <span>{record?.company_name}</span>
                </>
            ),
        },
        {
            title: "Inventory Count",
            dataIndex: "quantity",
            key: "quantity",
            sorter: true,
            render: (text, record) => (
                <>
                    <span>{record?.quantity}</span>
                </>
            ),
        },
    ];

    const UpcomingProspects = [
        {
            title: "S.No.",
            dataIndex: "serial",
            key: "serial",
            render: (_, record, index) => (
                <>
                    {(upcomingPage - 1) * 5 + index + 1}
                </>
            ),
        },
        {
            title: "Name",
            dataIndex: "first_name",
            key: "first_name",
            sorter: true,
            render: (text, record) => (
                <>
                    <span>{record?.patient_name}</span>
                </>
            ),
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            sorter: true,
            render: (text, record) => (
                <>
                    <span>{record?.patient?.email === "" ? "N/A" : record?.patient?.email }</span>
                </>
            ),
        },
    ];

    const expiredMedicineColumns = [
        {
            title: "S.No.",
            dataIndex: "serial",
            key: "serial",
            render: (_, record, index) => (
                <>
                    {(expiredPage - 1) * 10 + index + 1}
                </>
            ),
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: true,
            render: (text, record) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                        src={record.image}
                        alt={text}
                        style={{
                            width: 50,
                            height: 50,
                            marginRight: 20,
                            borderRadius: "5px",
                        }}
                    />
                    <span style={{ textTransform: "capitalize", fontWeight: 600 }}>
                        {record?.item_name}
                    </span>
                </div>
            ),
        },
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
            sorter: true,
            render: (text, record) => (
                <>
                    <span>
                        {record?.category?.name !== "" ? record?.category?.name : "N/A"}
                    </span>
                </>
            ),
        },
        {
            title: "Company Name",
            dataIndex: "company_name",
            key: "company_name",
            sorter: true,
            render: (text, record) => (
                <>
                    <span>
                        {record?.company_name !== "" ? record?.company_name : "N/A"}
                    </span>
                </>
            ),
        },
    ];

    const generatePageSizeOptions = (total) => {
        const options = [];
        const maxPageSize = Math.min(total, 100);
        for (let i = 5; i <= maxPageSize; i += 5) {
            options.push(i);
        }
        if (total > 5 && !options.includes(10)) {
            options.push(10);
        }

        if (total > 10 && !options.includes(15)) {
            options.push(15);
        }
        return options.sort((a, b) => a - b);
    };

    const MedicationOptions = generatePageSizeOptions(totalMedicationsPage);
    const InjectibleOptions = generatePageSizeOptions(totalInjectiblePage);
    const ExpiredOptions = generatePageSizeOptions(expiredProductsTotal);
    const UpcommingOptions = generatePageSizeOptions(upcomingPatientTotal);

    const onMedicationPageSizeChange = (current, size) => {
        setMedicationsPerPage(size);
        setMedicationsPage(current);
    };

    const onInjectiblePageSizeChange = (current, size) => {
        setInjectiblePerPage(size);
        setInjectiblePage(current);
    };

    const onExpiredPageSizeChange = (current, size) => {
        setExpiredPerPage(size);
        setExpiredPage(current);
    };

    const onUpcommingPageSizeChange = (current, size) => {
        setUpcomingPerPage(size);
        setUpcomingPage(current);
    };


    const handleLogout = () => {
        dispatch(logout());
        sessionStorage.clear();
        localStorage.clear();
        navigate("/login");
    };

    const handleLogoutClick = () => {

        setIsModalOpen(true);
        setLogoutClicked(true);
    };

    const handleProceed = () => {
        setIsModalOpen(false);
        navigate('/inventory')
    };

    const handleSkip = () => {
        if (logoutClicked) {
            setLogoutClicked(false);
            handleLogout();

        } else {
            setIsModalOpen(false);
            setLogoutClicked(false);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setLogoutClicked(false);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter.order) {
            setSortField(sorter.field);
            setSortOrder(sorter.order === "ascend" ? "asc" : "desc");
        } else {
            setSortField(null);
            setSortOrder(null);
        }
    };

    const handleExpiredTableChange = (pagination, filters, sorter) => {
        if (sorter.order) {
            setSortExpiredField(sorter.field);
            setSortExpiredOrder(sorter.order === "ascend" ? "asc" : "desc");
        } else {
            setSortExpiredField(null);
            setSortExpiredOrder(null);
        }
    };

    const handleLowStockTable = (pagination, filters, sorter) => {
        if (sorter.order) {
            setlowMedicationField(sorter.field);
            setlowMedicationOrder(sorter.order === "ascend" ? "asc" : "desc");
        } else {
            setlowMedicationField(null);
            setlowMedicationOrder(null);
        }
    };

    const handleLowInjectiblesTable = (pagination, filters, sorter) => {
        if (sorter.order) {
            setlowInjectiblesField(sorter.field);
            setlowInjectiblesOrder(sorter.order === "ascend" ? "asc" : "desc");
        } else {
            setlowInjectiblesField(null);
            setlowInjectiblesOrder(null);
        }
    };
    return (
        <div className="wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="titleBar d-flex justify-content-between">
                            <h4>Dashboard</h4>
                            <div className="buttonWrap">
                                <button
                                    type="button"
                                    className="btn backBtn"
                                    onClick={handleLogoutClick}
                                >
                                    <BiLogOutCircle /> SIGN OUT
                                </button>
                                {userData?.profile_image?.includes("https") ? (
                                    <img style={{ width: "35px", height: "35px", borderRadius: "50%", objectFit: "cover" }} src={userData?.profile_image} />
                                ) : (<FaUserCircle
                                    fontSize={35}
                                    onClick={() => navigate("/my-account")}
                                />)}


                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-50 mb-4">
                        <div className="dashboardItem" onClick={() => navigate("/patient-list")}>
                            <div className="leftSection">
                                <div className="dashIcon">
                                    <FaHospitalUser />
                                </div>
                                <h5>Total Patients</h5>
                            </div>

                            <div className={isPatientStatisticsLoading ? "" : "dashboardContent"}>
                                <span>
                                    {isPatientStatisticsLoading ? <Spin size="medium" /> : patientStastics?.total_patients}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-50 mb-4">
                        <div className="dashboardItem" onClick={() => console.log()}>
                            <div className="leftSection">
                                <div className="dashIcon">
                                    <FaWheelchair />
                                </div>
                                <h5>Patients Visited This Week</h5>
                            </div>
                            <div className={isPatientStatisticsLoading ? "" : "dashboardContent"}>
                                <span>
                                    {isPatientStatisticsLoading ? (
                                        <Spin size="medium" />
                                    ) : (
                                        patientStastics?.patient_visits_last_week
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-50 mb-4">
                        <div className="dashboardItem" onClick={() => console.log()}>
                            <div className="leftSection">
                                <div className="dashIcon">
                                    <FaHandHoldingMedical />
                                </div>
                                <h5>Total Patients in 90 Days</h5>
                            </div>
                            <div className={isPatientStatisticsLoading ? "" : "dashboardContent"}>
                                <span>
                                    {isPatientStatisticsLoading ? (
                                        <Spin size="medium" />
                                    ) : (
                                        patientStastics?.total_patients_last_90_days
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-50 mb-4">
                        <div className="dashboardItem" onClick={() => console.log()}>
                            <div className="leftSection">
                                <div className="dashIcon">
                                    <TbReportMedical />
                                </div>
                                <h5>Upcoming Appointments</h5>
                            </div>
                            <div className={isPatientStatisticsLoading ? "" : "dashboardContent"}>
                                <span>
                                    {isPatientStatisticsLoading ? <Spin size="medium" /> : patientStastics?.upcoming_appointment}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-4">
                        <div className="card dashboardCard">
                            <div className="card-header">
                                <h4 className="card-title">Low Stocks Medications</h4>
                            </div>
                            <div className="card-body">
                                <div className="tableWrapper">
                                    <Table
                                        className="dashboardTable productTable"
                                        dataSource={medications}
                                        columns={LowStockMedicationsColumns}
                                        loading={isProductLoading}
                                        pagination={{
                                            current: medicationsPage,
                                            pageSize: medicationsPerPage,
                                            total: totalMedicationsPage,
                                            onChange: (page) => setMedicationsPage(page),
                                            onShowSizeChange: onMedicationPageSizeChange,
                                            showSizeChanger: true,
                                            showQuickJumper: true,
                                            pageSizeOptions: MedicationOptions,
                                        }}
                                        onRow={(record) => ({
                                            onClick: () => handleProductDetails(record?.id),
                                        })}
                                        bordered
                                        onChange={handleLowStockTable}
                                        scroll={{ x: 'auto' }}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="card dashboardCard">
                            <div className="card-header">
                                <h4 className="card-title">Low Stocks Injectables</h4>
                            </div>
                            <div className="card-body">
                                <div className="tableWrapper">
                                    <Table
                                        className="dashboardTable productTable"
                                        dataSource={injectible}
                                        columns={LowStockInjectiblesColumns}
                                        loading={isProductLoading}
                                        pagination={{
                                            current: injectiblePage,
                                            pageSize: injectiblePerPage,
                                            total: totalInjectiblePage,
                                            onChange: (page) => setInjectiblePage(page),
                                            onShowSizeChange: onInjectiblePageSizeChange,
                                            showSizeChanger: true,
                                            showQuickJumper: true,
                                            pageSizeOptions: InjectibleOptions,
                                        }}
                                        onRow={(record) => ({
                                            onClick: () => handleProductDetails(record?.id),
                                        })}
                                        bordered
                                        onChange={handleLowInjectiblesTable}
                                        scroll={{ x: 'auto' }}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-4">
                        <div className="card dashboardCard">
                            <div className="card-header">
                                <h4 className="card-title">Upcoming Appointments</h4>
                            </div>
                            <div className="card-body">
                                <div className="tableWrapper">
                                    <Table
                                        className="dashboardTable productTable"
                                        dataSource={upcomingPatient}
                                        columns={UpcomingProspects}
                                        loading={isAppointmentLoading}
                                        onChange={handleTableChange}
                                        pagination={{
                                            current: upcomingPage,
                                            pageSize: upcomingPerPage,
                                            total: upcomingPatientTotal,
                                            onChange: (page) => setUpcomingPage(page),
                                            onShowSizeChange: onUpcommingPageSizeChange,
                                            showSizeChanger: true,
                                            showQuickJumper: true,
                                            pageSizeOptions: UpcommingOptions,
                                        }}
                                        scroll={{ x: 'auto' }}
                                        onRow={(record) => ({
                                            onClick: () => handlePatientDetails(record?.patient?.id),
                                        })}
                                        bordered

                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="card dashboardCard">
                            <div className="card-header">
                                <h4 className="card-title">Expired Medicine</h4>
                            </div>
                            <div className="card-body">
                                <div className="tableWrapper">
                                    <Table
                                        className="dashboardTable productTable"
                                        dataSource={expiredProducts}
                                        columns={expiredMedicineColumns}
                                        loading={isProductLoading}
                                        pagination={{
                                            current: expiredPage,
                                            pageSize: expiredPerPage,
                                            total: expiredProductsTotal,
                                            onChange: (page) => setExpiredPage(page),
                                            onShowSizeChange: onExpiredPageSizeChange,
                                            showSizeChanger: true,
                                            showQuickJumper: true,
                                            pageSizeOptions: ExpiredOptions,
                                        }}
                                        scroll={{ x: 'auto' }}
                                        onChange={handleExpiredTableChange}
                                        onRow={(record) => ({
                                            onClick: () => handleProductDetails(record?.id),
                                        })}
                                        bordered
                                    />


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                title="Inventory Update Reminder"
                open={isModalOpen}
                onCancel={handleCloseModal}
                footer={false}
                className="inventoryModal"
            >
                <div className="modalBody">
                    <div className="inventoryIcon">
                        <FcShop />
                    </div>
                    <p>Please update your inventory</p>
                    <div className="modalFooter">
                        <button type="button" className="btn skipBtn" onClick={() => handleSkip()}>{logoutClicked ? "Logout" : "Skip"}</button>
                        <button type="button" className="btn proceedBtn" onClick={() => handleProceed()}>Proceed</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Dashboard;
