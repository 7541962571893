import React, { useState, useEffect } from "react";
import { Table, Input, DatePicker } from "antd";
import { useGetRecentPatientsMutation } from '../../redux/slices/patientSlice';
import { MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import debounce from '../../HelperFunctions';
import dayjs from "dayjs"

const { RangePicker } = DatePicker;

const PatientList = () => {
   const navigate = useNavigate()
   const [getRecentPatients, { isLoading }] = useGetRecentPatientsMutation();
   const [filteredPatientData, setFilteredPatientData] = useState([]);
   const [patientTotal, setPatientTotal] = useState(0);
   const [filteredPatientTotal, setFilteresPatientTotal] = useState(0);
   const [patientPerPage, setPatientPerPage] = useState(10);
   const [patientPage, setPatientPage] = useState(1);
   const [search, setSearch] = useState('');
   const [totalPatients, setTotalPatients] = useState(null);
   const [sortField, setSortField] = useState(null);
   const [sortOrder, setSortOrder] = useState(null);
   const [selectedDateRange, setSelectedDateRange] = useState([
      dayjs().subtract(30, "day"),
      dayjs(),
   ]);

   const getPatientData = (value) => {
      getRecentPatients(value).then((response) => {
         setFilteredPatientData(response?.data?.data);
         setPatientTotal(response?.data?.total);
         setFilteresPatientTotal(response?.data?.total);
         setTotalPatients(response?.data?.total_patients);
      }).catch((err) => {
         console.error("err", err);
      });
   }

   useEffect(() => {
      let query = `?per_page=${patientPerPage}&page=${patientPage}`;

      if (search !== '') {
         query += `&search=${search}`
      }
      if (sortField && sortOrder) {
         query += `&sort_by=${sortField}&sort_direction=${sortOrder}`;
      }
      if (selectedDateRange && selectedDateRange?.length === 2) {
         const formattedStartDate = dayjs(selectedDateRange[0]).format("YYYY-MM-DD");
         const formattedEndDate = dayjs(selectedDateRange[1]).format("YYYY-MM-DD");
         query += `&start_date=${formattedStartDate}&end_date=${formattedEndDate}`;
      }
      getPatientData(query)
   }, [patientPerPage, patientPage, sortField, sortOrder, search, selectedDateRange])

   const onPageSizeChange = (current, size) => {
      setPatientPerPage(size);
      setPatientPage(1);
   };

   const generatePageSizeOptions = (total) => {
      const options = [];
      const maxPageSize = Math.min(total, 100);

      for (let i = 5; i <= maxPageSize; i += 5) {
         options.push(i);
      }
      if (total > 5 && !options.includes(10)) {
         options.push(10);
      }

      if (total > 10 && !options.includes(15)) {
         options.push(15);
      }
      return options.sort((a, b) => a - b);
   };

   const pageSizeOptions = generatePageSizeOptions(patientTotal);

   const handleTableChange = (pagination, filters, sorter) => {
      if (sorter.order) {
         setSortField(sorter.field);
         setSortOrder(sorter.order === "ascend" ? "asc" : "desc");
      } else {
         setSortField(null);
         setSortOrder(null);
      }
   };

   const tableColumns = [
      {
         title: "S.No",
         key: "serial_number",
         render: (text, record, index) =>
            <span>{(patientPage - 1) * patientPerPage + index + 1}</span>,
      },
      {
         title: "Patient Name",
         dataIndex: "first_name",
         key: "first_name",
         sorter: true,
         render: (text, record) => (
            <span>{`${record?.first_name} ${record?.last_name}`}</span>
         )
      },
      {
         title: "Email",
         dataIndex: "email",
         key: "email",
         sorter: true,
         render: (text, record) => (
            <span>{record?.email || "N/A"}</span>
         )
      },
      {
         title: "Phone",
         dataIndex: "phone",
         key: "phone",
         sorter: true,
         render: (text, record) => (
            <span>{record?.phone || "N/A"}</span>
         )
      },
      {
         title: "Address",
         dataIndex: "address",
         key: "address",
         sorter: true,
         render: (text, record) => (
            <span>{record?.address || "N/A"}</span>
         )
      },
      {
         title: "City",
         dataIndex: "city",
         key: "city",
         sorter: true,
         render: (text, record) => (
            <span>{record?.city || "N/A"}</span>
         )
      },
      {
         title: "State",
         dataIndex: "state",
         key: "state",
         sorter: true,
         render: (text, record) => (
            <span>{record?.state || "N/A"}</span>
         )
      },
      {
         title: "Zip",
         dataIndex: "zip",
         key: "zip",
         sorter: true,
         render: (text, record) => (
            <span>{record?.zip || "N/A"}</span>
         )
      },
      {
         title: "Action",
         dataIndex: "action",
         key: "action",
         render: (text, record) => (
            <MdEdit style={{ cursor: "pointer", color: '#b4a099' }} onClick={() => navigate("/edit-patient", {
               state: { patientId: record?.id }
            })} />
         )
      },
   ];

   const handlePatientSearch = async (event) => {
      setPatientPage(1)
      setSearch(event?.target?.value);
   };

   const handleOnChangeDate = (dates) => {
      setSelectedDateRange(dates);

      if (dates && dates.length === 2) {
         const formattedStartDate = dayjs(dates[0]).format("YYYY-MM-DD");
         const formattedEndDate = dayjs(dates[1]).format("YYYY-MM-DD");

         let query = `?per_page=${patientPerPage}&page=${patientPage}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`;
         if (sortField && sortOrder) {
            query += `&sort_by=${sortField}&sort_direction=${sortOrder}`;
         }
         getPatientData(query);
      } else {
         let query = `?per_page=${patientPerPage}&page=${patientPage}`;
         if (sortField && sortOrder) {
            query += `&sort_by=${sortField}&sort_direction=${sortOrder}`;
         }
         getPatientData(query);
      }
   };

   return (
      <div className="wrapper">
         <div className="container-fluid">
            <div className="row mb-5">
               <div className="col-md-12">
                  <div className="titleBar titleRow">
                     <h4>Patient List</h4>
                     <div className="d-flex align-items-center">
                        <div className="dateRangeWrapper" style={{ marginRight: '1rem' }}>
                        <label htmlFor="lastVisitSelect" className="dynamicLabel">Last Visited</label>
                           <RangePicker
                              format="MM/DD/YYYY"
                              className="formSelect w-100"
                              allowClear
                              value={selectedDateRange}
                              ranges={{
                                 Today: [dayjs(), dayjs()],
                                 "This Week": [dayjs().startOf("week"), dayjs().endOf("week")],
                                 "This Month": [dayjs().startOf("month"), dayjs().endOf("month")],
                                 "Last Month": [
                                    dayjs().subtract(1, "month").startOf("month"),
                                    dayjs().subtract(1, "month").endOf("month"),
                                 ],
                                 "Last 6 Months": [
                                    dayjs().subtract(6, "month").startOf("month"),
                                    dayjs().endOf("month"),
                                 ],
                                 "Last 30 Days": [dayjs().subtract(30, "day"), dayjs()],
                              }}
                              onChange={(dates) => handleOnChangeDate(dates)}
                           />
                        </div>
                        <div className='inputContainer' style={{marginBottom: '0px'}}>
                        <label htmlFor="lastVisitSelect" className="dynamicLabel">Search Here</label>
                        <Input
                           style={{ height: "50px" }}
                           placeholder="Search here.."
                           onChange={handlePatientSearch}
                           className="homesearchInput titleRowInput"
                           allowClear
                        />
                     </div>
                     </div>
                  </div>
               </div>
               <div className="col-md-12">
                  <div className="tableWrapper customPaginationTable">
                     <Table
                        className="registered-user-table"
                        columns={tableColumns}
                        dataSource={isLoading ? [] :filteredPatientData}
                        pagination={{
                           current: patientPage,
                           pageSize: patientPerPage,
                           total: filteredPatientTotal,
                           onChange: (page) => setPatientPage(page),
                           onShowSizeChange: onPageSizeChange,
                           showSizeChanger: true,
                           showQuickJumper: true,
                           pageSizeOptions: pageSizeOptions,
                        }}
                        loading={isLoading}
                        onChange={handleTableChange}
                        showSorterTooltip={false}
                        scroll={{ x: 'auto' }}
                        bordered
                        footer={() => <>
                           <span><b>Total Patients:</b> {totalPatients}</span>
                        </>}
                     />
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default PatientList;