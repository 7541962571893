import React, { useState, useEffect } from 'react';
import { Table, Spin, Input, Button, Modal, Tag } from "antd";
import {
    useGetOrderMutation
} from "../../redux/slices/productSlice";
import moment from 'moment';

export default function SingleOrderList() {
    const [orderData, setorderData] = useState([]);
    const [orderTotal, setOrderTotal] = useState(0);
    const [orderPerPage, setOrderPerPage] = useState(10);
    const [orderPage, setOrderPage] = useState(1);
    const [orderSearch, setOrderSearch] = useState('');
    const [detailModal, setDetailModal] = useState(false);
    const [patientDetails, setPatientDetails] = useState();
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);

    const [getOrder, { isLoading: isLoadinggetOrder }] = useGetOrderMutation();

    const generatePageSizeOptions = (total) => {
        const options = [];
        const maxPageSize = Math.min(total, 100);

        for (let i = 5; i <= maxPageSize; i += 5) {
            options.push(i);
        }
        if (total > 5 && !options.includes(10)) {
            options.push(10);
        }

        if (total > 10 && !options.includes(15)) {
            options.push(15);
        }
        return options.sort((a, b) => a - b);
    };

    const pageSizeOptions = generatePageSizeOptions(orderTotal);

    const handleOrderSearch = (event) => {
        setOrderPage(1)
        setOrderPerPage(10)
        setOrderSearch(event?.target?.value)
    };

    const getOrderData = (query) => {
        getOrder(query)
            .then((res) => {
                setorderData(res?.data?.data?.data);
                setOrderTotal(res?.data?.data?.total);
            })
            .catch((err) => {
                console.error("err", err);
            });
    }

    useEffect(() => {
        let query = `?per_page=${orderPerPage}&page=${orderPage}`
        if (orderSearch !== '') {
            query += `&search=${orderSearch}`
        }
        if (sortField && sortOrder) {
            query += `&sort_by=${sortField}&sort_order=${sortOrder}`;
        }
        getOrderData(query);
    }, [orderPerPage, orderPage, orderSearch, sortField, sortOrder]);

    const Patientdetailscolumn = [
        {
            title: "S.No",
            key: "serial_number",
            render: (text, record, index) =>
                <span>{(orderPage - 1) * orderPerPage + index + 1}</span>,
        },
        {
            title: "Patient Name",
            dataIndex: "first_name",
            key: "first_name",
            sorter: true,
            render: ((text, record) => (
                <>
                    <span>{`${record?.data?.order?.patient?.firstName} ${record?.data?.order?.patient?.lastName}`}</span>
                </>
            ))
        },
        {
            title: "Drug Name",
            dataIndex: "drugName",
            key: "drugName",
            sorter: true,
            render: ((text, record) => (
                <>
                    <span>{`${record?.data?.order?.rxs[0]?.drugName}`}</span>
                </>
            ))
        },
        {
            title: "Date Last Ordered",
            dataIndex: "last_order_date",
            key: "last_order_date",
            sorter: true,
            render: ((text, record) => (
                <>
                    <span>{moment(record?.patients[0]?.last_order_date).format('MM/DD/YY')}</span>
                </>
            ))
        },
        {
            title: "Order Quantity",
            dataIndex: "quantity",
            key: "quantity",
            sorter: true,
            render: ((text, record) => (
                <>
                    <span>{record?.data?.order?.rxs[0]?.quantity ? record?.data?.order?.rxs[0]?.quantity : "N/A"}</span>
                </>
            ))
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            sorter: true,
            render: ((text, record) => (
                <>
                    <span>${record?.amount}</span>
                </>
            ))
        },
        {
            title: "Days",
            dataIndex: "daysSupply",
            key: "daysSupply",
            sorter: true,
            render: ((text, record) => (
                <>
                    <span>{record?.data?.order?.rxs[0]?.daysSupply ? record?.data?.order?.rxs[0]?.daysSupply : "N/A"}</span>
                </>
            ))
        },
        // {
        //     title: "Delivery Address",
        //     dataIndex: "deliveryAddress",
        //     key: "deliveryAddress",
        //     render: ((text, record) => (
        //         <>
        //             <span>{record?.data?.order?.patient?.address1 ? record?.data?.order?.patient?.address1 : "N/A"}</span>
        //         </>
        //     ))
        // },
        {
            title: "Order Details",
            dataIndex: "info",
            key: "info",
            render: ((text, record) => (
                <>
                    <Button className="craete-order-button" onClick={() => { setDetailModal(true); setPatientDetails(record) }}>
                        View Details
                    </Button>
                </>
            ))
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: ((text, record) => (
                <>
                    {record?.status === "success" ? <Tag color="green">Successfull</Tag> : <Tag color="red">Denied</Tag>}
                </>
            ))
        },
        {
            title: "Reason",
            dataIndex: "reason",
            key: "reason",
            render: ((text, record) => (
                <>
                    <span>{record?.denial_reason ? record?.denial_reason : "N/A"}</span>
                </>
            ))
        },
    ];

    const onPageSizeChange = (current, size) => {
        setOrderPerPage(size);
        setOrderPage(1);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter.order) {
            setSortField(sorter.field);
            setSortOrder(sorter.order === "ascend" ? "asc" : "desc");
        } else {
            setSortField(null);
            setSortOrder(null);
        }
    };

    return (

        <div className="wrapper">
            <div className="container-fluid">
                <div className="row mb-5">
                    <div className="col-md-12">
                        <div className="titleBar titleRow">
                            <h4>Single Order List</h4>
                            <div className='inputContainer' style={{marginBottom: '0px'}}>
                            <label htmlFor="lastVisitSelect" className="dynamicLabel">Search Order</label>
                            <Input
                                placeholder="Search Order.."
                                onChange={handleOrderSearch}
                                className="homesearchInput titleRowInput customPadding"
                                allowClear
                            />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="tableWrapper">
                            <Table
                                className="registered-user-table"
                                columns={Patientdetailscolumn}
                                dataSource={isLoadinggetOrder ? [] :orderData}
                                pagination={{
                                    current: orderPage,
                                    pageSize: orderPerPage,
                                    total: orderTotal,
                                    onChange: (page) => setOrderPage(page),
                                    onShowSizeChange: onPageSizeChange,
                                    showSizeChanger: true,
                                    showQuickJumper: true,
                                    pageSizeOptions: pageSizeOptions,
                                }}
                                loading={isLoadinggetOrder}
                                showSorterTooltip={false}
                                scroll={{ x: 'auto' }}
                                bordered
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                title="Order Details"
                centered
                open={detailModal}
                onOk={() => { setDetailModal(false); setPatientDetails() }}
                onCancel={() => { setDetailModal(false); setPatientDetails() }}
                className="bulk-order-modal"
                footer={false}
                width={700}
            >
                <div className="tableWrapper registered-user-table mt-4">
                    <div className='row'>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Patient Name:</label> {patientDetails?.data?.order?.patient?.firstName + ' ' + patientDetails?.data?.order?.patient?.lastName ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Drug Name:</label> {patientDetails?.data?.order?.rxs[0]?.drugName ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Drug Strength:</label> {patientDetails?.data?.order?.rxs[0]?.drugStrength ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Drug Form:</label> {patientDetails?.data?.order?.rxs[0]?.drugForm ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Quantity Unit:</label> {patientDetails?.data?.order?.rxs[0]?.quantityUnits ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Amount:</label> ${patientDetails?.amount ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Clinical Statement:</label> {patientDetails?.data?.order?.rxs[0]?.clinicalDifferenceStatement ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Drug Directions:</label> {patientDetails?.data?.order?.rxs[0]?.directions ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Patient Address:</label> {patientDetails?.data?.order?.patient?.address1 ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Shipping Address:</label> {patientDetails?.data?.order?.new_shipping_address ? patientDetails?.data?.order?.new_shipping_address : patientDetails?.shipping?.addressLine1 ?? "N/A"}</div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
